import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import CourseFilterSidebar from './pages/course/CourseFilterSidebar';
import AboutUsOne from './pages/innerpages/AboutUsOne';
import BlogGridOne from './pages/blog/BlogGridOne';
import BlogGridTwo from './pages/blog/BlogGridTwo';
import BlogGridThree from './pages/blog/BlogGridThree';
import BlogCarousel from './pages/blog/BlogCarousel';
import BlogLoadMore from './pages/blog/BlogLoadMore';
import BlogStandard from './pages/blog/BlogStandard';
import BlogGridRightSidebar from './pages/blog/BlogGridRightSidebar';
import BlogGridLeftSidebar from './pages/blog/BlogGridLeftSidebar';
import BlogDetailsOne from './pages/detailspages/BlogDetailsOne';
import BlogDetailsTwo from './pages/detailspages/BlogDetailsTwo';
import BlogDetailsThree from './pages/detailspages/BlogDetailsThree';
import CategoryArchive from './pages/archive/CategoryArchive';
import TagArchive from './pages/archive/TagArchive';
import AuthorArchive from './pages/archive/AuthorArchive';
import ContactUs from './pages/innerpages/ContactUs';
import CourseDetails from './pages/detailspages/CourseDetails';
import CourseDetailsTwo from './pages/detailspages/CourseDetailsTwo';
import EventGrid from './pages/innerpages/EventGrid';
import EventList from './pages/innerpages/EventList';
import EventLoadMore from './pages/innerpages/EventLoadMore';
import EventCarousel from './pages/innerpages/EventCarousel';
import EventDetails from './pages/detailspages/EventDetails';
import Faq from './pages/innerpages/Faq';
import GalleryGrid from './pages/innerpages/GalleryGrid';
import GalleryMasonry from './pages/innerpages/GalleryMasonry';
import GalleryLoadMore from './pages/innerpages/GalleryLoadMore';
import HomeOne from './pages/homepages/HomeOne';
import InstructorPageOne from './pages/innerpages/InstructorPageOne';
import InstructorPageTwo from './pages/innerpages/InstructorPageTwo';
import InstructorPageThree from './pages/innerpages/InstructorPageThree';
import LoginRegister from './pages/innerpages/LoginRegister';
import Pricing from './pages/innerpages/Pricing';
import PrivacyPolicy from './pages/innerpages/PrivacyPolicy';
import PurchaseGuide from './pages/innerpages/PurchaseGuide';
import Testimonial from './pages/innerpages/Testimonial';
import InstructorDetails from './pages/detailspages/InstructorDetails';
import Error from './pages/innerpages/Error';

// Import Css Here 
import './assets/scss/style.scss';

function App() {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route exact path='/' element={<HomeOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/home-1'}`} element={<HomeOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/about'}`} element={<AboutUsOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/contact-us'}`} element={<ContactUs/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-details/:id'}`} element={<CourseDetails/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-details-two/:id'}`} element={<CourseDetailsTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-filter-sidebar'}`} element={<CourseFilterSidebar />}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/event-grid'}`} element={<EventGrid/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/event-list'}`} element={<EventList/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/event-load-more'}`} element={<EventLoadMore/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/event-carousel'}`} element={<EventCarousel/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/event-details/:id'}`} element={<EventDetails/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/faq'}`} element={<Faq/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/gallery-grid'}`} element={<GalleryGrid/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/gallery-masonry'}`} element={<GalleryMasonry/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/gallery-load-more'}`} element={<GalleryLoadMore/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-one'}`} element={<InstructorPageOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-two'}`} element={<InstructorPageTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-three'}`} element={<InstructorPageThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/pricing'}`} element={<Pricing/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/privacy-policy'}`} element={<PrivacyPolicy/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/purchase-guide'}`} element={<PurchaseGuide/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/testimonial'}`} element={<Testimonial/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-details/:slug'}`} element={<InstructorDetails/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog'}`} element={<BlogGridOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-2'}`} element={<BlogGridTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-3'}`} element={<BlogGridThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-carousel'}`} element={<BlogCarousel/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-load-more'}`} element={<BlogLoadMore/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-standard'}`} element={<BlogStandard/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-right-sidebar'}`} element={<BlogGridRightSidebar/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-left-sidebar'}`} element={<BlogGridLeftSidebar/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/login-register'}`} element={<LoginRegister/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-details/:id'}`} element={<BlogDetailsOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-details-left-sidebar/:id'}`} element={<BlogDetailsTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-details-right-sidebar/:id'}`} element={<BlogDetailsThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/category/:slug'}`} element={<CategoryArchive/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/tag/:slug'}`} element={<TagArchive/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/author/:slug'}`} element={<AuthorArchive/>}/>
                    <Route path='*' element={<Error/>}/>
                </Routes>
            </ScrollToTop>
        </Router>
    );
}

export default App;
