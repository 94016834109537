import React from 'react';
import SEO from '../../common/SEO';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AboutSeven from '../../components/about/AboutSeven';
import AboutSix from '../../components/about/AboutSix';
import AboutUsOneTeam from '../../components/about-us-one/AboutUsOneTeam';
import HomeTwoTestimonial from '../../components/home-two/HomeTwoTestimonial';
import Timeline from '../../components/timeline/Timeline';
import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';

const AboutUsOne = () => {
    return (
        <>
            <SEO title="About Us" />

            <HeaderTwo styles='header-transparent header-style-2' />

            <BreadcrumbOne  title="About Us" rootUrl="/" parentUrl="Home" currentUrl="About Us" />

            <AboutSeven />

            <Timeline />

            <AboutSix />

            <AboutUsOneTeam />

            <HomeTwoTestimonial />

            <FooterOne />
        </>
    )
}

export default AboutUsOne;