import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TbTargetArrow } from 'react-icons/tb';
import { GiEyeTarget } from 'react-icons/gi';
import { PiHandHeartLight } from 'react-icons/pi';
import './timeline.css'

const Timeline = () => {
  return (
    <Container className='my-5'>
      <Row>
        <Col md={12}>
          <div className="main-timeline9">
            <div className="timeline">
              <div className="timeline-content">
                <div className="circle"><span><TbTargetArrow size='1em' /></span></div>
                <div className="content">
                  <span className="year">Mission</span>

                  <div className="description">
                    <p><strong>Our Culture: What Makes Us Stand Out </strong></p>
                    <p>At our affiliate course company, our culture sets us apart from the rest. Here are a few things that make us unique:</p>
                    <p><strong>Student Empowerment:</strong> We believe in empowering and inspiring our students to reach their full potential. Our courses are designed to provide practical knowledge and actionable strategies that can lead to real results.
                    </p>
                    <p><strong>Collaborative Environment:</strong> We foster a collaborative and supportive community where students can connect, share ideas, and learn from each other. We believe that collaboration leads to growth and success.
                    </p>
                    <p><strong>Innovation:</strong> We are constantly staying ahead of the curve and adapting to the ever-changing affiliate marketing landscape. Our courses are updated regularly to provide the latest industry insights and strategies.
                    </p>
                    <p><strong>Results-Oriented Approach:</strong> We are driven by results. Our focus is not just on providing information, but on helping our students achieve tangible outcomes and see their efforts translate into success.
                    </p>
                    <p><strong>Continuous Learning:</strong> We believe that learning is a lifelong journey. We encourage our students to embrace a mindset of continuous learning and personal growth, both within our courses and beyond.
                    </p>
                  </div>
                  <div className="icon"><span></span></div>
                </div>
              </div>
            </div>
            <div className="timeline">
              <div className="timeline-content">
                <div className="circle"><span><PiHandHeartLight size='1em' /></span></div>
                <div className="content">
                  <span className="year">Values</span>
                  <div className="description">
                    <p><strong>Our values - the pillar of our success</strong></p>
                    <p>We hold several core values close to our hearts.</p>
                    <p><strong>Being helpful:</strong> We are dedicated to providing exceptional support and guidance to our customers, ensuring their success every step of the way.</p>
                    <p><strong>Simplicity</strong> We believe in keeping things simple and user-friendly. Our platform is designed with a clean and intuitive interface, making it easy for you to navigate and achieve your goals.</p>
                    <p><strong>Ease of use:</strong> We strive to make your experience as seamless as possible. Our platform is designed to be user-friendly, allowing you to effortlessly access the resources and tools you need.</p>
                    <p><strong>Integrity:</strong> We value honesty and transparency in everything we do. You can trust that we will always act with integrity, ensuring your best interests are at the forefront of our decisions.</p>
                    <p><strong>Passion and love for what we do:</strong> We are truly passionate about what we do and the impact it can have on your success. We pour our hearts into creating a platform that inspires and empowers you to achieve your dreams.</p>
                  </div>
                  <div className="icon"><span></span></div>
                </div>
              </div>
            </div>
            <div className="timeline">
              <div className="timeline-content">
                <div className="circle"><span><GiEyeTarget size='1em' /></span></div>
                <div className="content">
                  <span className="year">Vision</span>
                  <div className="description">
                    <p><strong>Long term vision</strong></p>
                    <p>So, for our long-term vision, we see ourselves as a trailblazing platform in the world of e-commerce. Our aim is to empower individuals, like yourself, to launch successful e-commerce businesses and earn commission through product-based partnerships. We envision a future where our platform serves as a one-stop-shop for aspiring entrepreneurs, providing them with top-notch training, resources, and support to navigate the dynamic e-commerce landscape. Together, let's turn your e-commerce dreams into a thriving reality!</p>
                  </div>
                  <div className="icon"><span></span></div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Timeline