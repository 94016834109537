import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from './sectionTitle/SectionTitle';

const AboutFounder = () => {
  return (
    <div className=' eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white'>
      <div className='container eduvibe-animated-shape'>
        <div className='row row--50'>
          <div className='col-lg-6'>
            <div className='about-image-gallery'>
              <div className='eduvibe-about-1-img-wrapper'>
                <img
                  className='image-1'
                  src='/images/instructor/instructor-01/instructor-1.jpg'
                  alt='About Images'
                />
                <span className='eduvibe-about-blur'>
                  <img
                    src='/images/about/about-07/about-blur.png'
                    alt='About Blur'
                  />
                </span>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='inner mt_md--40 mt_sm--40'>
              <SectionTitle classes='text-start' title='About Founder' />
              <ScrollAnimation
                animateIn='fadeInUp'
                animateOut='fadeInOut'
                animateOnce={true}
              >
                <p className='description mt--40 mt_md--20 mt_sm--20'>
                  Meet Abhilash Chauhan a tech-savvy entrepreneur who graduated
                  with a Bachelor of Computer Application degree. With a deep
                  understanding of technology and a genuine passion for aﬃliate
                  marketing, Abhilash Chauhan out to create an innovative and
                  accessible platform for aspiring marketers like you. Their
                  expertise in computer applications, combined with their
                  commitment to simplicity, integrity, and continuous learning,
                  has been instrumental in shaping our courses. Join us on this
                  exciting journey and let empower you to achieve your aﬃliate
                  marketing goals!
                </p>
                <h6 className='subtitle mb--20'>Founder - Abhilash Chauhan</h6>
              </ScrollAnimation>
            </div>
          </div>
        </div>

        <div className='shape-dot-wrapper shape-wrapper d-xl-block d-none'>
          <div className='shape-image shape-image-1'>
            <img src='/images/shapes/shape-11-05.png' alt='Shape Thumb' />
          </div>
          <div className='shape-image shape-image-2'>
            <img src='/images/shapes/shape-08-01.png' alt='Shape Thumb' />
          </div>
          <div className='shape-image shape-image-3'>
            <img src='/images/shapes/shape-30.png' alt='Shape Thumb' />
          </div>
          <div className='shape shape-1'>
            <span className='shape-dot'></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFounder;
