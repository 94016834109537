import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const AboutFour = () => {
    return (
      <div className='edu-about-area about-style-1 about-style-3 edu-section-gap bg-image'>
        <div className='container'>
          <div className='row g-5'>
            <div className='col-lg-6'>
              <div className='about-image-gallery'>
                <img
                  className='image-1'
                  src='/images/about/about-09/about-image-01.jpg'
                  alt='About Main Thumb'
                />
                <div className='image-2'>
                  <img
                    src='/images/about/about-09/about-image-02.jpg'
                    alt='About Parallax Thumb'
                  />
                </div>
                <div className='badge-inner'>
                  <img
                    className='image-3'
                    src='/images/about/about-09/badge.png'
                    alt='About Circle Thumb'
                  />
                </div>
                <div className='shape-image shape-image-1'>
                  <img src='/images/shapes/shape-04-01.png' alt='Shape Thumb' />
                </div>
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='inner'>
                <SectionTitle
                  classes='text-start'
                  slogan='About Joinheart'
                  title='Creating A Community Of Life Long Learners'
                />

                <ScrollAnimation
                  animateIn='fadeInUp'
                  animateOut='fadeInOut'
                  animateOnce={true}
                >
                  <p className='description mt--40'>
                    Welcome to JOINHEART We have a passionate team of aﬃliate
                    marketers who believe in the power of education and sharing
                    knowledge. Our goal is to empower aspiring marketers like
                    you with the skills and strategies needed to succeed in the
                    world of aﬃliate marketing. With our comprehensive courses,
                    expert guidance, and a supportive community, we're here to
                    help you unlock your full potential. Join us on this
                    exciting journey and let's make your aﬃliate marketing
                    dreams a reality!
                  </p>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn='fadeInUp'
                  animateOut='fadeInOut'
                  className='read-more-btn mt--60 mt_lg--30 mt_md--30 mt_sm--30'
                  animateOnce={true}
                >
                  <Link className='edu-btn' to='#'>
                    Learn More <i className='icon-arrow-right-line-right'></i>
                  </Link>
                </ScrollAnimation>
              </div>
            </div>
          </div>

          <div className='shape-dot-wrapper shape-wrapper d-xl-block d-none'>
            <div className='shape-image shape-image-1'>
              <img src='/images/shapes/shape-21.png' alt='Shape Thumb' />
            </div>
            <div className='shape-image shape-image-2'>
              <img src='/images/shapes/shape-13-04.png' alt='Shape Thumb' />
            </div>
            <div className='shape-image shape-image-3'>
              <img src='/images/shapes/shape-03-05.png' alt='Shape Thumb' />
            </div>
            <div className='shape-image shape-image-4'>
              <img src='/images/shapes/shape-15-02.png' alt='Shape Thumb' />
            </div>
          </div>
        </div>
      </div>
    );
}

export default AboutFour;