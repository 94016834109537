import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerOne from '../../components/banner/BannerOne';
import AboutFour from '../../components/about/AboutFour';
import HomeOneCategory from '../../components/home-one/HomeOneCategory';
import HomeFourCourses from '../../components/home-four/HomeFourCourses';
import TestimonialSectionOne from '../../components/testimonial-section/TestimonialSectionOne';
import HomeFourTeam from '../../components/home-four/HomeFourTeam';
import VideoSectionTwo from '../../components/video-section/VideoSectionTwo';
import NewsLettterOne from '../../components/newsletter/NewsLettterOne';
import HomeFiveProgress from '../../components/home-five/HomeFiveProgress';
import FooterOne from '../../common/footer/FooterOne';
import AboutFounder from '../../components/AboutFounder';

const HomeOne = () => {
  return (
    <>
      <SEO title='Home 1' />

      <HeaderTwo styles='header-transparent header-style-2' />

      <BannerOne />

      <AboutFour />

      <AboutFounder />

      <HomeFourCourses />

      <VideoSectionTwo />

      <HomeFiveProgress />

      <TestimonialSectionOne />

      <HomeOneCategory />

      <HomeFourTeam />

      <NewsLettterOne />

      <FooterOne />
    </>
  );
};

export default HomeOne;
