import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    return (
      <ul className='mainmenu'>
        <li>
          <Link to='/'>Home</Link>
        </li>

        <li className='has-droupdown'>
          <Link to='#'>Courses</Link>
          <ul className='submenu'>
            <li>
              <Link to='/course-details/1'>Bronze Package</Link>
            </li>
            <li>
              <Link to='/course-details/4'>Silver Package</Link>
            </li>
            <li>
              <Link to='/course-details/2'>Gold Package</Link>
            </li>
            <li>
              <Link to='/course-details/3'>Platinum Package</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to='/about'>About</Link>
        </li>

        <li>
          <Link to='/blog'>Blog</Link>
        </li>

        <li>
          <Link to='/contact-us'>Contact</Link>
        </li>
      </ul>
    );
}
export default Nav;
