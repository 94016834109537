import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';

const FooterOne = () => {
    return (
      <>
        <footer className='eduvibe-footer-one edu-footer footer-style-default'>
          <div className='footer-top'>
            <div className='container eduvibe-animated-shape'>
              <div className='row g-5'>
                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                  <div className='edu-footer-widget'>
                    <div className='logo'>
                      <Link
                        to={process.env.PUBLIC_URL + '/'}
                        style={{ color: '#fff', fontSize: '2em' }}
                      >
                        JoinHeart
                      </Link>
                    </div>
                    <p className='description'>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum.
                    </p>
                    <ul className='social-share'>
                      <li>
                        <a href='/'>
                          <i className='icon-Fb'></i>
                        </a>
                      </li>
                      <li>
                        <a href='/'>
                          <i className='icon-linkedin'></i>
                        </a>
                      </li>
                      <li>
                        <a href='/'>
                          <i className='icon-Pinterest'></i>
                        </a>
                      </li>
                      <li>
                        <a href='/'>
                          <i className='icon-Twitter'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div className='edu-footer-widget explore-widget'>
                    <h5 className='widget-title'>Explore</h5>
                    <div className='inner'>
                      <ul className='footer-link link-hover'>
                        <li>
                          <Link to='/about'>
                            <i className='icon-Double-arrow'></i>About Us
                          </Link>
                        </li>
                        <li>
                          <Link to='/blog'>
                            <i className='icon-Double-arrow'></i>Blog & News
                          </Link>
                        </li>
                        <li>
                          <Link to='/'>
                            <i className='icon-Double-arrow'></i>FAQ Question
                          </Link>
                        </li>
                        <li>
                          <Link to='/'>
                            <i className='icon-Double-arrow'></i>Testimonial
                          </Link>
                        </li>
                        <li>
                          <Link to='/privacy-policy'>
                            <i className='icon-Double-arrow'></i>Privacy Policy
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div className='edu-footer-widget quick-link-widget'>
                    <h5 className='widget-title'>Useful Links</h5>
                    <div className='inner'>
                      <ul className='footer-link link-hover'>
                        <li>
                          <Link to='/contact-us'>
                            <i className='icon-Double-arrow'></i>Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link to='/'>
                            <i className='icon-Double-arrow'></i>Pricing Plan
                          </Link>
                        </li>
                        <li>
                          <Link to='/'>
                            <i className='icon-Double-arrow'></i>Instructor
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link to='/'>
                            <i className='icon-Double-arrow'></i>Popular Courses
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div className='edu-footer-widget'>
                    <h5 className='widget-title'>Contact Info</h5>
                    <div className='inner'>
                      <div className='widget-information'>
                        <ul className='information-list'>
                          <li>
                            <i className='icon-map-pin-line'></i>Baghpat
                            bypass Meerut (UP)
                          </li>
                          <li>
                            <i className='icon-phone-fill'></i>
                            <a href='tel: +919627031469'>+919627031469</a>
                          </li>
                          <li>
                            <i className='icon-mail-line-2'></i>
                            <a
                              target='_blank'
                              rel='noreferrer'
                              href='mailto:joinheartrequest@gmail.com'
                            >
                              joinheartrequest@gmail.com
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='shape-dot-wrapper shape-wrapper d-md-block d-none'>
                <div className='shape-image shape-image-1'>
                  <img src='/images/shapes/shape-21-01.png' alt='Shape Thumb' />
                </div>
                <div className='shape-image shape-image-2'>
                  <img src='/images/shapes/shape-35.png' alt='Shape Thumb' />
                </div>
              </div>
            </div>
          </div>
          <div className='copyright-area copyright-default'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='inner text-center'>
                    <p>
                      Copyright 2023 <a href='/'>JoinHeart</a> Designed By{' '}
                      <a href='/'>Ribe Web Solutions</a>. All Rights Reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <ScrollTopButton />
      </>
    );
}

export default FooterOne;